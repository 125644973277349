import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import apiClient from "../../api/apiClient"
import { manageUsers } from "../../api/apiRequests"
import { setErrorAlert } from "./errorAlertSlice"

let initialStates = {
  usersData: [],
  userInfo: {
    name: "",
    email: "",
    permission: "",
    status: "Active",
    clubId: "",
  },
  error: "",
  close: false,
  loading: false,
}

const getInvites = createAsyncThunk("club/getInvites", async thunkAPI => {
  try {
    const res = await apiClient(manageUsers.getUsers(thunkAPI))

    return res.data
  } catch (e) {
    console.log("err getInvites:", e)
    return e
  }
})

const sendInvites = createAsyncThunk(
  "club/sendInvites",
  async (thunkAPI, { dispatch }) => {
    dispatch(manageUserErrorMessage(""))
    dispatch(manageUserDrawerLoading(true))
    try {
      const res = await apiClient(manageUsers.addNewUser(thunkAPI))
      if (res?.data?.success) {
        dispatch(manageUserDrawerClose(true))
        dispatch(
          setErrorAlert({
            isVisible: true,
            message: "Invite sent successfully.",
            title: "success",
            severity: "success",
            duration: 5000,
          })
        )
        dispatch(manageUserDrawerLoading(false))
      }
      return res.data
    } catch (e) {
      dispatch(manageUserErrorMessage(e?.response?.data?.message))
    }
    dispatch(manageUserDrawerLoading(false))
  }
)
const editInvites = createAsyncThunk(
  "club/sendInvites",
  async (thunkAPI, { dispatch }) => {
    dispatch(manageUserDrawerLoading(true))

    try {
      const res = await apiClient(manageUsers.editUser(thunkAPI))
      if (res.data.success) {
        dispatch(
          actions.editUser({
            id: thunkAPI.params.inviteId,
            data: res.data.data,
          })
        )
        dispatch(manageUserDrawerClose(true))

        dispatch(
          setErrorAlert({
            isVisible: true,
            message: "Invite updated successfully.",
            title: "success",
            severity: "success",
            duration: 5000,
          })
        )
        dispatch(manageUserDrawerLoading(false))
      }
    } catch (e) {
      console.log("err send invites:", e)
      dispatch(
        setErrorAlert({
          isVisible: true,
          message: "Something went wrong. Cannot update invite.",
          title: "error",
          severity: "error",
          duration: 5000,
        })
      )
      // return e
    }
    dispatch(manageUserDrawerLoading(false))
  }
)

const deleteInvites = createAsyncThunk(
  "club/deleteInvites",
  async (thunkAPI, { dispatch }) => {
    dispatch(manageUserDrawerLoading(true))
    try {
      const res = await apiClient(manageUsers.deleteUser(thunkAPI))
      if (res.data.success) {
        dispatch(
          actions.removeUser({
            id: thunkAPI.params.inviteId,
          })
        )
        dispatch(manageUserDrawerClose(true))
        dispatch(
          setErrorAlert({
            isVisible: true,
            message: "Invite deleted successfully.",
            title: "success",
            severity: "success",
            duration: 5000,
          })
        )
        dispatch(manageUserDrawerLoading(false))
      }
      return res.data
    } catch (e) {
      console.log("err send invites:", e)
      dispatch(
        setErrorAlert({
          isVisible: true,
          message: "Something went wrong. Invite not deleted.",
          title: "error",
          severity: "error",
          duration: 5000,
        })
      )
      // return e
    }
    dispatch(manageUserDrawerLoading(false))
  }
)

export const manageUserSettingSlice = createSlice({
  name: "manage-user-settings",
  initialState: {
    ...initialStates,
  },
  reducers: {
    manageUserFieldsInfo: (state, action) => {
      const { key1, key2, value } = action.payload
      state[key1][key2] = value
    },
    selectedUser: (state, action) => {
      state.userInfo = action.payload
    },
    editUser: (state, action) => {
      const { data, id } = action.payload
      let index = state.usersData.findIndex(item => item.id === id)
      state.usersData[index] = data
    },
    removeSelectedUser: (state, action) => {
      state.userInfo = initialStates.userInfo
    },
    addUser: (state, action) => {
      state.usersData = [...state.usersData, action.payload]
    },
    removeUser: (state, action) => {
      let { id } = action.payload
      state.usersData = state.usersData.filter(item => item.id !== id)
    },
    manageUserErrorMessage: (state, action) => {
      state.error = action.payload
    },
    manageUserDrawerClose: (state, action) => {
      state.close = action.payload
    },
    manageUserDrawerLoading: (state, action) => {
      state.loading = action.payload
    },
    resetUserSlice: () => initialStates,
  },
  extraReducers: {
    [getInvites.pending]: state => {
      state.loading = true
    },
    [getInvites.fulfilled]: (state, action) => {
      const { payload } = action
      state.usersData = payload?.data || []
      state.loading = false
    },
    [getInvites.rejected]: state => {
      state.loading = false
    },
    [sendInvites.pending]: state => {
      state.loading = true
    },
    [sendInvites.fulfilled]: (state, action) => {
      const { payload } = action
      state.loading = false
      payload?.success && state.usersData.push(payload?.data)
    },
    [sendInvites.rejected]: state => {
      state.loading = false
    },
  },
})

const {
  editUser,
  addUser,
  removeUser,
  resetUserSlice,
  manageUserFieldsInfo,
  selectedUser,
  removeSelectedUser,
  manageUserErrorMessage,
  manageUserDrawerClose,
  manageUserDrawerLoading,
} = manageUserSettingSlice.actions

export const actions = {
  editUser,
  addUser,
  removeUser,
  resetUserSlice,
  manageUserFieldsInfo,
  manageUserErrorMessage,
  manageUserDrawerClose,
  manageUserDrawerLoading,
  selectedUser,
  removeSelectedUser,
  getInvites,
  sendInvites,
  editInvites,
  deleteInvites,
}

export default manageUserSettingSlice.reducer
