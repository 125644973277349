import { createSlice } from "@reduxjs/toolkit"

const errorAlertSlice = createSlice({
  name: "errorAlert",
  initialState: {
    isVisible: false,
    message: "",
    title: "",
    severity: "",
    duration: 2000,
  },
  reducers: {
    setErrorAlert: (state, action) => {
      state.isVisible = action.payload.isVisible
      state.message = action.payload.message
      state.title = action.payload.title
      state.severity = action.payload.title
      state.duration = action.payload.duration
    },
    setSuccessAlert: (state, action) => {
      state.isVisible = action.payload.isVisible
      state.message = action.payload.message
      state.title = action.payload.title
      state.severity = action.payload.title
      state.duration = action.payload.duration
    },
  },
})

export const { setErrorAlert, setSuccessAlert } = errorAlertSlice.actions

export default errorAlertSlice.reducer
