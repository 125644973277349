import { combineReducers } from "redux"
import { configureStore } from "@reduxjs/toolkit"
import clubReducer from "../slices/clubSlice"
import courtSettingsReducer from "../slices/seetingsCourtSlice"
import manageUserReducer from "../slices/manageUserSlice"
import scheduleSlice from "../slices/scheduleSlice"
import blockingUserSlice from "../slices/blockingUserSlice"
import userSlice from "../slices/userSlice"
import bannerSlice from "../slices/bannerSlice"
import subscriptionModalSlice from "../slices/subscriptionModalSlice"
import deleteConfirmModalSlice from "../slices/deleteConfirmModalSlice"
import errorAlertSlice from "../slices/errorAlertSlice"
import activitiesSlice from "../slices/activitiesSlice"
import showDescriptionModalSlice from "../slices/showDescriptionModalSlice"
import notificationsSlice from "../slices/notificationsSlice"
import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore } from "redux-persist"
import { getDefaultMiddleware } from "@reduxjs/toolkit/dist"

const persistConfig = {
  key: "root",
  storage,
  whiteList: ["clubReducer", "userSlice"],
  blacklist: [
    "courtSettingsReducer",
    "manageUserReducer",
    "scheduleSlice",
    "bannerSlice",
    "subscriptionModalSlice",
    "deleteConfirmModalSlice",
    "errorAlertSlice",
    "activitiesSlice",
    "showDescriptionModalSlice",
    "blockingUserSlice",
    "notificationsSlice",
  ],
  debug: true,
}

let reducers = combineReducers({
  clubReducer,
  courtSettingsReducer,
  manageUserReducer,
  scheduleSlice,
  userSlice,
  bannerSlice,
  subscriptionModalSlice,
  deleteConfirmModalSlice,
  errorAlertSlice,
  activitiesSlice,
  showDescriptionModalSlice,
  blockingUserSlice,
  notificationsSlice,
  // devTools: process.env.NODE_ENV !== 'production',
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const createStore = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ["persist/PERSIST"],
    },
  }),
})
export const persistor = persistStore(createStore)
