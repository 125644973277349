import { createSlice } from "@reduxjs/toolkit"

const deleteConfirmModal = createSlice({
  name: "DeleteConfirmModal",
  initialState: {
    isVisible: false,
  },
  reducers: {
    showDeleteConfirmModal: (state, action) => {
      state.isVisible = action.payload
    },
  },
})

export const { showDeleteConfirmModal } = deleteConfirmModal.actions

export default deleteConfirmModal.reducer
