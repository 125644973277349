import { createSlice } from "@reduxjs/toolkit"

let initialStates = {
  clubInfo: {
    clubName: "",
    address: "",
    postalCode: "",
    clubWebsite: "",
    clubEmail: "",
    phoneNumber: "",
    clubLocations: "",
    clubPhoneCode: "",
    clubImage: "",
    openingHours: {
      monday: { start: "8:00", end: "19:00", isChecked: true },
      tuesday: { start: "8:00", end: "19:00", isChecked: true },
      wednesday: { start: "8:00", end: "19:00", isChecked: true },
      thursday: { start: "8:00", end: "19:00", isChecked: true },
      friday: { start: "8:00", end: "19:00", isChecked: true },
      saturday: { start: "8:00", end: "19:00", isChecked: true },
      sunday: { start: "8:00", end: "19:00", isChecked: true },
      holidays: { start: "", end: "", isChecked: false },
    },
  },
  courtInfo: [
    {
      courtName: "",
      courtType: "",
      sports: "",
      status: "Active",
      bookingLength: "",
      userId: 25,
      currency: "",
      price: "",
    },
  ],
  billingInfo: {
    name: "",
    email: "",
  },
  isActive: false,
}

export const clubSlice = createSlice({
  name: "club",
  initialState: {
    ...initialStates,
  },

  reducers: {
    clubInfo: (state, action) => {
      const { key1, key2, value } = action.payload
      console.log("key1:", key1, "key2:", key2, "value:", value)
      state[key1][key2] = value
    },
    editClubInfo: (state, action) => {
      state.clubInfo = { ...state.clubInfo, ...action.payload }
    },
    activateOpeningHours: state => {
      state.isActive = true
    },
    courtInfo: (state, action) => {
      const { key1, key2, value, index } = action.payload
      state[key1][index][key2] = value
    },
    handleMultipleCourt: (state, action) => {
      const { key1, key2, value, index } = action.payload
      state[key1][index][key2] = value
    },
    addCourt: state => {
      state = state.courtInfo.push({
        ...initialStates.courtInfo[0],
        status: "Active",
        userId: 25,
      })
    },
    removeCourt: (state, action) => {
      let { index } = action.payload

      state.courtInfo.splice(index, 1)
    },
    removeInfo: () => initialStates,
  },
})

const {
  clubInfo,
  removeInfo,
  courtInfo,
  handleMultipleCourt,
  removeCourt,
  addCourt,
  editClubInfo,
  activateOpeningHours,
} = clubSlice.actions

export const actions = {
  clubInfo,
  removeInfo,
  courtInfo,
  handleMultipleCourt,
  removeCourt,
  addCourt,
  editClubInfo,
  activateOpeningHours,
}

export default clubSlice.reducer
