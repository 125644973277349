import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import apiClient from "../../api/apiClient"
import { notificationsApi } from "../../api/apiRequests"

let initialStates = {
  allNotifications: [],
  bookingNotifications: [],
  isNotificationSeen: true,
}

const notificationsSlice = createSlice({
  name: "notificationsSlice",
  initialState: {
    ...initialStates,
  },

  reducers: {
    setAllNotifications(state, action) {
      state.allNotifications = action.payload
    },
    setBookingNotifications(state, action) {
      state.bookingNotifications = action.payload.filter(
        notification => notification.type === "booking"
      )
    },
    setIsNotificationSeen(state, action) {
      state.isNotificationSeen = action.payload
    },
    updateIsNotificationSeen(state, action) {
      state.isNotificationSeen = action.payload
    },
  },
})

export const getNotifications = createAsyncThunk(
  "notifications/get",
  async (body, { dispatch }) => {
    try {
      const res = await apiClient(
        notificationsApi.getNotifications(body.clubId)
      )

      dispatch(setAllNotifications(res?.data?.data?.data))
      dispatch(setBookingNotifications(res?.data?.data?.data))
      dispatch(setIsNotificationSeen(res?.data?.data?.isNotificationSeen))
    } catch (error) {
      console.log(error)
    }
  }
)

export const updateSeenNotification = createAsyncThunk(
  "notifications/updateSeen",
  async (body, { dispatch }) => {
    try {
      const res = await apiClient(
        notificationsApi.updateSeenNotification(body.clubId)
      )

      dispatch(updateIsNotificationSeen(res?.data?.data?.isNotificationSeen))
    } catch (error) {
      console.log(error)
    }
  }
)

export const {
  setAllNotifications,
  setBookingNotifications,
  setIsNotificationSeen,
  updateIsNotificationSeen,
} = notificationsSlice.actions

export const actions = {
  setAllNotifications,
  setBookingNotifications,
  setIsNotificationSeen,
  updateIsNotificationSeen,
}

export default notificationsSlice.reducer
