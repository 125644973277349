import { createSlice } from "@reduxjs/toolkit"

const descriptionModal = createSlice({
  name: "ShowDescriptionModal",
  initialState: {
    isVisible: false,
  },
  reducers: {
    showDescriptionModal: (state, action) => {
      state.isVisible = action.payload
    },
  },
})

export const { showDescriptionModal } = descriptionModal.actions

export default descriptionModal.reducer
