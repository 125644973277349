import axios from "axios"
import { createStore } from "../redux/store/index"
import { setProfileVerificationModal } from "../redux/slices/blockingUserSlice"
import { auth } from "../firebase/firebase-config"
const axiosInstance = axios.create({
  baseURL: process.env.GATSBY_API_BASE_URL,
  // timeout: 10000,
})

const apiClient = (config, retry = true) => {
  return new Promise(async function (resolve, reject) {
    let token = await auth.currentUser?.getIdToken()
    let axiosConfig = { ...config }

    if (
      config.url != "/api/v1/clubs/accept/invites" &&
      config.url != "/api/v1/verifyEmail"
    ) {
      axiosConfig.headers = token
        ? { ...axiosConfig.headers, authorization: token }
        : axiosConfig.headers
    }

    axiosInstance(axiosConfig)
      .then(res => {
        if (res?.data?.emailNotVerified) {
          // createStore.dispatch(setProfileVerificationModal(true))
        }
        resolve(res)
      })
      .catch(err => {
        console.log("err00---000", err)
        // err?.response?.data?.error &&
        //   Sentry.captureException(err?.response?.data?.error);

        reject(err)
      })
  })
}

export default apiClient
