import React from "react"
import { Provider } from "react-redux"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { persistor, createStore } from "./src/redux/store/index"
import { PersistGate } from "redux-persist/integration/react"

const WrapProvider = ({ element }) => {
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

  return (
    <Elements stripe={stripePromise}>
      <Provider store={createStore}>
        <PersistGate loading={null} persistor={persistor}>
          {element}
        </PersistGate>
      </Provider>
    </Elements>
  )
}

export default WrapProvider
