import { createSlice } from "@reduxjs/toolkit"

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    isActive: false,
  },
  reducers: {
    activateBanner: (state, action) => {
      state.isActive = true
    },
    deactivateBanner: (state, action) => {
      state.isActive = false
    },
  },
})

export const { activateBanner, deactivateBanner } = bannerSlice.actions

export default bannerSlice.reducer
