import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { auth } from "../../firebase/firebase-config"
import {
  setPersistence,
  signInWithCustomToken,
  signOut,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth"
import axios from "axios"
import apiClient from "../../api/apiClient"
import { clubsApi, users } from "../../api/apiRequests"
import { getBillingDetail } from "../slices/subscriptionModalSlice"
import { setProfileVerificationModal } from "../slices/blockingUserSlice"

const initialState = {
  user: null,
  status: "idle",
  error: null,
}

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = state.user
        ? { ...state.user, ...action.payload }
        : action.payload
      state.status = "succeeded"
    },
    changeDisplayName(state, action) {
      state.user = {
        ...state.user,
        userInfo: {
          ...state.user.userInfo,
          userInfo: {
            ...state.user.userInfo.userInfo,
            name: action.payload.displayName,
          },
        },
      }
      state.status = "succeeded"
    },
    changePicture(state, action) {
      state.user = {
        ...state.user,
        userInfo: {
          ...state.user.userInfo,
          userInfo: {
            ...state.user.userInfo.userInfo,
            profileImage: action.payload.profileImage,
          },
        },
      }
      state.status = "succeeded"
    },

    changeClubPicture(state, action) {
      state.user = {
        ...state.user,
        userInfo: {
          ...state.user.userInfo,
          clubImage: action.payload.clubImage,
        },
      }
      state.status = "succeeded"
    },
    clearUser(state) {
      state.user = null
      state.status = "succeeded"
    },
    setError(state, action) {
      state.error = action.payload
      state.status = "failed"
    },
    clearError(state) {
      state.error = null
      state.status = "idle"
    },
    loginStart: state => {
      state.loading = true
      state.error = null
    },
    loginSuccess: (state, action) => {
      state.user = action.payload
      state.isLoading = false
      state.error = null
    },
    loginFailure: (state, action) => {
      state.user = null
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  setUser,
  clearUser,
  setError,
  clearError,
  loginStart,
  loginSuccess,
  loginFailure,
  setInitialRender,
  changePicture,
  changeClubPicture,
  changeDisplayName,
} = userSlice.actions

export const actions = {
  setUser,
  setError,
  clearUser,
  clearError,
  loginStart,
  loginSuccess,
  loginFailure,
  setInitialRender,
  changeClubPicture,
  changeDisplayName,
}

export const changeName = createAsyncThunk(
  "user/changeName",
  (body, { dispatch }) => {
    const currentUser = auth.currentUser

    const user = {
      uid: currentUser.uid,
      displayName: body.name,
      email: currentUser.email,
    }

    dispatch(setUser(user))
    dispatch(changeDisplayName({ displayName: body.name }))
  }
)

export const changeProfilePicture = createAsyncThunk(
  "user/changeProfilePicture",
  (body, { dispatch }) => {
    dispatch(
      changePicture({
        profileImage: body.profileImage,
      })
    )
  }
)

export const changeClubImage = createAsyncThunk(
  "club/changeClubPicture",
  async (body, { dispatch }) => {
    try {
      await apiClient(
        clubsApi.changeClubImage({
          clubId: body.clubId,
          clubImage: body.clubImage,
        })
      )

      dispatch(
        changeClubPicture({
          clubImage: body.clubImage,
        })
      )
    } catch (error) {
      dispatch(setError(error.message))
    }
  }
)

export const loginWithToken = createAsyncThunk(
  "user/loginWithToken",
  async (body, { dispatch }) => {
    try {
      await setPersistence(auth, browserLocalPersistence)
      const res = await signInWithCustomToken(auth, body.token)

      const currentUser = auth.currentUser

      const user = {
        uid: currentUser.uid,
        displayName: body.name || currentUser.displayName,
        email: currentUser.email,
        accessToken: res.user.accessToken,
        ...body,
      }

      dispatch(setUser(user))
    } catch (error) {
      dispatch(setError(error.message))
    }
  }
)

export const signInWithEmailAndPass = createAsyncThunk(
  "user/signInWithEmailAndPass",
  async (body, { dispatch }) => {
    try {
      dispatch(loginStart())
      const userCredential = await signInWithEmailAndPassword(
        auth,
        body.email,
        body.password
      )

      const user = userCredential.user

      dispatch(
        loginSuccess({
          accessToken: user.accessToken,
          displayName: user.displayName,
          email: user.email,
          uid: user.uid,
        })
      )
      return user
    } catch (error) {
      dispatch(loginFailure(error.message))
      throw error
    }
  }
)

export const logoutUser = createAsyncThunk(
  "user/logoutUser",
  async (_, { dispatch }) => {
    try {
      localStorage.removeItem("persist:root")
      dispatch(clearUser())
      await signOut(auth)
    } catch (error) {
      dispatch(setError(error.message))
    }
  }
)

export const getUserDetail = createAsyncThunk(
  "user/details",
  async (thunkAPI, { dispatch }) => {
    try {
      axios
        .get(
          `${process.env.GATSBY_API_BASE_URL}/api/v1/userDetail/${thunkAPI.params.id}`
        )
        .then(res => {
          const { isSubscribed, ...otherInfo } = res?.data?.data
          dispatch(setUser({ userInfo: otherInfo, isSubscribed }))
          dispatch(
            getBillingDetail({
              params: {
                clubOwnerId: otherInfo?.clubOwner,
                userId: thunkAPI.params.id,
              },
            })
          )
        })
        .catch(err => {
          console.log("err", err)
        })
    } catch (e) {
      console.log("err getUser:", e)
      return e
    }
  }
)

export default userSlice.reducer
