import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import apiClient from "../../api/apiClient"
import { activityApi } from "../../api/apiRequests"

let initialStates = {
  activities: [],
}

const activitiesSlice = createSlice({
  name: "activitiesSlice",
  initialState: {
    ...initialStates,
  },
  reducers: {
    setActivities(state, action) {
      state.activities = action.payload
    },
    addActivity(state, action) {
      state.activities = [...state.activities, action.payload]
    },
    removeActivity(state, action) {
      let { id } = action.payload
      state.activities = state.activities.filter(
        activity => activity?.uuid !== id
      )
    },
  },
})

export const getActivities = createAsyncThunk(
  "activity/getActivities",
  async (body, { dispatch }) => {
    try {
      const res = await apiClient(
        activityApi.fetchActivity({ clubId: body?.clubId, date: body.date })
      )

      dispatch(setActivities(res?.data?.data))
    } catch (error) {
      console.log("error getActivities", error)
    }
  }
)

export const {
  setActivities,
  addActivity,
  removeActivity,
  saveDeletedActivityID,
} = activitiesSlice.actions

export const actions = {
  setActivities,
  addActivity,
  removeActivity,
  saveDeletedActivityID,
}

export default activitiesSlice.reducer
