import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import apiClient from "../../api/apiClient"
import { subscription, users } from "../../api/apiRequests"
import { setUser } from "./userSlice"
import { setErrorAlert } from "./errorAlertSlice"
import { showDeleteConfirmModal } from "./deleteConfirmModalSlice"

export const getBillingDetail = createAsyncThunk(
  "club/getBillingDetail",
  async (thunkAPI, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const res = await apiClient(subscription.getBillingDetail(thunkAPI))
      if (res.status === 200) {
        let { data } = res.data
        dispatch(setBillingDetail(data))
        let { clubOwnerId, userId } = thunkAPI?.params
        if (data?.showTrialEndModal && userId === clubOwnerId) {
          dispatch(setTrialModal(true))
        }
      }
      dispatch(setLoading(false))
    } catch (e) {
      console.log("err getBillingDetail", e)
      dispatch(setLoading(false))
      return e
    }
  }
)

export const activeSubscription = createAsyncThunk(
  "club/activateSubscription",
  async (thunkAPI, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const res = await apiClient(subscription.activateSubscription(thunkAPI))
      const { data } = res?.data
      dispatch(showSubscriptionModal(false))
      if (
        res.status === 200 &&
        data?.subscription?.status !== "requires_action" &&
        data?.subscription?.next_action?.type !== "use_stripe_sdk"
      ) {
        dispatch(
          setUser({ isSubscribed: res?.data?.data?.isSubscribed || false })
        )

        dispatch(setBillingDetail(res.data.data))
        dispatch(setLoading(false))
      } else if (
        data.subscription.status === "requires_action" &&
        data.subscription.next_action.type === "use_stripe_sdk"
      ) {
        dispatch(setLoading(false))
        dispatch(
          setSecureAuth({
            status: true,
            clientSecret: data.subscription.client_secret,
          })
        )
      }
    } catch (e) {
      console.log("err getInvites:", e)
      dispatch(setLoading(false))
      return e
    }
  }
)

export const updateUserSubscriptionStatus = createAsyncThunk(
  "club/changeUserSusbcriptionStatus",
  async (thunkAPI, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const res = await apiClient(users.updateUserSubscriptionStatus(thunkAPI))

      dispatch(
        setUser({ isSubscribed: true, endDate: res.data?.data?.endDate })
      )

      dispatch(setLoading(false))
    } catch (e) {
      console.log("err getInvites:", e)
      dispatch(setLoading(false))
      return e
    }
  }
)

export const cancelSubscription = createAsyncThunk(
  "club/cancelSubs",
  async (thunkAPI, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      await apiClient(subscription.cancelSubscription(thunkAPI))

      dispatch(setUser({ isSubscribed: false }))

      dispatch(setLoading(false))
      dispatch(showDeleteConfirmModal(false))
    } catch (e) {
      console.log("err getInvites:", e)
      dispatch(setLoading(false))
      dispatch(showDeleteConfirmModal(false))
      return e
    }
  }
)
export const addPaymentCard = createAsyncThunk(
  "club/addPaymentMethod",
  async (thunkAPI, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const res = await apiClient(subscription.addPaymentMethod(thunkAPI))
      if (res.status === 200) {
        dispatch(setUser({ ...res?.data?.data }))
        dispatch(setBillingDetail(res.data.data))
      }

      dispatch(setLoading(false))
    } catch (e) {
      console.log("err getInvites:", e)
      dispatch(setLoading(false))
      return e
    }
  }
)
export const updatePaymentCard = createAsyncThunk(
  "club/updatePaymentMethod",
  async (thunkAPI, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const res = await apiClient(subscription.updatePaymentMethod(thunkAPI))
      if (res.status === 200) {
        dispatch(setUser({ ...res?.data?.data }))
        dispatch(setBillingDetail(res.data.data))
      }
      dispatch(setLoading(false))
      dispatch(showDeleteConfirmModal(false))
    } catch (e) {
      console.log("err updatePaymentCard:", e)
      dispatch(setLoading(false))
      dispatch(showDeleteConfirmModal(false))
      return e
    }
  }
)

export const deletePaymentCard = createAsyncThunk(
  "club/deletePaymentMethod",
  async (thunkAPI, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const res = await apiClient(subscription.removeCard(thunkAPI))
      if (res.status === 200) {
        dispatch(setUser({ ...res?.data?.data }))
        dispatch(resetBillingDetail(res.data.data))
        dispatch(
          setErrorAlert({
            isVisible: true,
            message: "Card Deleted successfully",
            title: "success",
            severity: "success",
            duration: 5000,
          })
        )
      }
      dispatch(showDeleteConfirmModal(false))
      dispatch(setLoading(false))
    } catch (e) {
      console.log("err updatePaymentCard:", e)
      dispatch(setLoading(false))
      dispatch(showDeleteConfirmModal(false))
      return e
    }
  }
)
export const changeTrialEndModalStatus = createAsyncThunk(
  "club/ChangeTrialEndModalStatus",
  async (thunkAPI, { dispatch }) => {
    try {
      const res = await apiClient(
        subscription.updateIsTrialModalVisible(thunkAPI)
      )
      if (res.status === 200) dispatch(setBillingDetail(res.data.data))
    } catch (e) {
      console.log("err updatePaymentCard:", e)

      return e
    }
  }
)

const subscriptionModal = createSlice({
  name: "SubscriptionModal",
  initialState: {
    initialRender: false,
    isVisible: false,
    openPaymentModal: false,
    billingInfo: null,
    loading: false,
    showTrialModal: false,
    authSecureRequired: { status: false, clientSecret: "" },
  },
  reducers: {
    showSubscriptionModal: (state, action) => {
      state.isVisible = action.payload
    },
    showPaymentModal: (state, action) => {
      state.openPaymentModal = action.payload
    },
    setBillingDetail: (state, action) => {
      state.billingInfo = state.billingInfo
        ? { ...state.billingInfo, ...action.payload }
        : action.payload
    },
    resetBillingDetail: (state, action) => {
      state.billingInfo = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSecureAuth: (state, action) => {
      const { status, clientSecret } = action.payload
      state.authSecureRequired = { status, clientSecret }
    },
    setInitialRender(state) {
      state.initialRender = true
    },
    setTrialModal(state, action) {
      state.showTrialModal = action.payload
    },
  },
})

export const {
  showSubscriptionModal,
  showPaymentModal,
  setBillingDetail,
  setLoading,
  setSecureAuth,
  resetBillingDetail,
  setInitialRender,
  setTrialModal,
} = subscriptionModal.actions

export default subscriptionModal.reducer
