import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import moment from "moment"
import apiClient from "../../api/apiClient"
import { booking } from "../../api/apiRequests"

let initialStates = {
  bookingsData: [],
  bookingInfo: {
    court: "",
    date: moment(),
    startTime: "",
    endTime: "",
  },
  isLoading: false,
  selectedDate: moment().format("YYYY-MM-DD"),
}

export const createBooking = createAsyncThunk(
  "booking/addBooking",
  async (thunkAPI, { dispatch }) => {
    try {
      const res = await apiClient(booking.createBooking(thunkAPI))

      dispatch(addSlot(res.data.data))

      return res.data
    } catch (e) {
      console.log("err add bookings:", e)
      return e
    }
  }
)

export const updateBooking = createAsyncThunk(
  "booking/updateBooking",
  async (thunkAPI, { dispatch }) => {
    try {
      const res = await apiClient(booking.updateBooking(thunkAPI))
      dispatch(editSlot({ id: thunkAPI.params.bookingId, data: thunkAPI.body }))

      return res.data
    } catch (e) {
      console.log("err add bookings:", e)
      return e
    }
  }
)

export const fetchBooking = createAsyncThunk(
  "booking/getBookings",
  async (thunkAPI, { dispatch }) => {
    dispatch(setLoading(true))
    try {
      const res = await apiClient(booking.getBooking(thunkAPI))
      dispatch(setBookings(res.data.data))
      dispatch(setLoading(false))

      return res.data
    } catch (e) {
      console.log("err get bookings:", e)
      dispatch(setLoading(false))

      return e
    }
  }
)

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState: {
    ...initialStates,
  },

  reducers: {
    bookingFieldsInfo: (state, action) => {
      const { key1, key2, value } = action.payload

      state[key1][key2] = value
    },
    setBookings: (state, action) => {
      state.bookingsData = action.payload
    },
    selectedSlot: (state, action) => {
      state.bookingInfo = action.payload
    },
    editSlot: (state, action) => {
      const { data, id } = action.payload
      let index = state.bookingsData.findIndex(item => item.id === id)

      state.bookingsData[index] = data
    },
    removeSelectedSlot: (state, action) => {
      state.bookingInfo = initialStates.bookingInfo
    },

    addSlot: (state, action) => {
      const { date } = action.payload
      state.bookingsData =
        date === state.selectedDate
          ? [...state.bookingsData, action.payload]
          : state.bookingsData
    },
    changeSelectedDate: (state, action) => {
      state.selectedDate = action.payload
    },
    removeSlot: (state, action) => {
      let { id } = action.payload

      state.bookingsData = state.bookingsData.filter(item => item.id !== id)
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    resetBookingSlice: () => initialStates,
  },
})

const {
  editSlot,
  addSlot,
  removeSlot,
  resetBookingSlice,
  bookingFieldsInfo,
  selectedSlot,
  removeSelectedSlot,
  setBookings,
  changeSelectedDate,
  setLoading,
} = scheduleSlice.actions

export const actions = {
  editSlot,
  addSlot,
  removeSlot,
  resetBookingSlice,
  bookingFieldsInfo,
  selectedSlot,
  removeSelectedSlot,
  changeSelectedDate,
  setLoading,
}

export default scheduleSlice.reducer
