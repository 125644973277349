const commonHeaders = {
  "Content-Type": "application/json",
}

export const profileApi = {
  changePassword: (body, token) => ({
    method: "PUT",
    url: "/api/v1/auth/change-password",
    headers: commonHeaders,
    data: JSON.stringify(body),
  }),
  changeDisplayName: (body, token) => ({
    method: "PUT",
    url: "/api/v1/auth/change-name",
    headers: commonHeaders,
    data: JSON.stringify(body),
  }),
  changeProfileImage: body => ({
    method: "PUT",
    url: "/api/v1/user/changeProfileImage",
    headers: commonHeaders,
    data: JSON.stringify(body),
  }),
}

export const clubsApi = {
  addClub: body => ({
    method: "POST",
    url: "/api/v1/clubs/sign_up",
    headers: commonHeaders,
    data: JSON.stringify(body),
  }),
  editClub: body => ({
    method: "PUT",
    url: `/api/v1/clubs/${body.clubId}/editClub`,
    headers: commonHeaders,
    data: JSON.stringify({ ...body.clubInfo, location: { ...body.location } }),
  }),
  changeClubImage: body => ({
    method: "PUT",
    url: "/api/v1/clubs/changeProfileImage",
    headers: commonHeaders,
    data: JSON.stringify(body),
  }),
}

export const activityApi = {
  createActivity: body => ({
    method: "POST",
    url: "/api/v1/activities/create",
    headers: commonHeaders,
    data: JSON.stringify(body),
  }),
  fetchActivity: body => ({
    method: "POST",
    url: `/api/v1/activities/show`,
    headers: commonHeaders,
    data: JSON.stringify(body),
  }),
  deleteActivity: activityId => ({
    method: "DELETE",
    url: `/api/v1/activities/${activityId}`,
    header: commonHeaders,
  }),
}

export const manageUsers = {
  addNewUser: payload => ({
    method: "POST",
    url: `/api/v1/clubs/${payload.params.clubId}/invites/create`,
    headers: commonHeaders,
    data: payload.body,
  }),

  getUsers: payload => ({
    method: "GET",
    url: `/api/v1/clubs/${payload.params.clubId}/invites`,
    headers: commonHeaders,
  }),

  editUser: payload => ({
    method: "PUT",
    url: `/api/v1/clubs/${payload.params.clubId}/invites/${payload.params.inviteId}/update`,
    headers: commonHeaders,
    data: payload.body,
  }),

  deleteUser: payload => ({
    method: "DELETE",
    url: `/api/v1/clubs/${payload.params.clubId}/invites/${payload.params.inviteId}`,
    headers: commonHeaders,
  }),
}

export const products = {
  addProduct: payload => ({
    method: "POST",
    url: `/api/v1/clubs/${payload.clubId}/products`,
    headers: commonHeaders,
    data: payload,
  }),

  allProducts: payload => ({
    method: "GET",
    url: `/api/v1/clubs/${payload.clubId}/products?getAllProducts=${payload?.queryParams?.getAllProducts}`,
    headers: commonHeaders,
  }),

  editProduct: payload => ({
    method: "PUT",
    url: `/api/v1/products/${payload.id}`,
    headers: commonHeaders,
    data: payload.data,
  }),

  deleteProduct: payload => ({
    method: "DELETE",
    url: `/api/v1/products/${payload.id}`,
    headers: commonHeaders,
  }),
}

export const invitation = {
  acceptInvite: payload => ({
    method: "POST",
    url: `/api/v1/clubs/accept/invites`,
    headers: { ...commonHeaders, ...payload?.header },
    data: JSON.stringify(payload?.body),
  }),
}

export const users = {
  getUserDetail: payload => ({
    method: "GET",
    url: `/api/v1/userDetail/${payload.params.id}`,
    headers: commonHeaders,
  }),
  updateUserSubscriptionStatus: payload => ({
    method: "PUT",
    url: `/api/v1/stripe/updateSubscriptionStatus`,
    headers: commonHeaders,
  }),
  resendVerificationEmail: payload => ({
    method: "POST",
    url: `/api/v1/send/verifyEmail`,
    headers: commonHeaders,
    data: payload,
  }),
  verifyEmail: payload => ({
    method: "PUT",
    url: `/api/v1/verifyEmail`,
    headers: { ...commonHeaders, ...payload.header },
    data: payload,
  }),
}

export const booking = {
  createBooking: payload => ({
    method: "POST",
    url: `/api/v1/booking/create/${payload.params.clubId}`,
    headers: commonHeaders,
    data: payload.body,
  }),
  getBooking: payload => ({
    method: "GET",
    url: `/api/v1/clubBookings/${payload.params.clubId}/bookings?date=${payload.params.queryParams}`,
    headers: commonHeaders,
  }),
  updateBooking: payload => ({
    method: "PUT",
    url: `/api/v1/booking/update/${payload.params.clubId}/${payload.params.bookingId}`,
    headers: commonHeaders,
    data: payload.body,
  }),

  getBookingAvailableSlots: payload => ({
    method: "GET",
    url: `/api/v1/clubs/AvailableSlots/${payload.params.clubId}/${payload.params.productId}?date=${payload.params.date}`,
    headers: commonHeaders,
    data: payload.body,
  }),
}

export const subscription = {
  getBillingDetail: payload => ({
    method: "GET",
    url: `/api/v1/stripe/GetBillingDetail/${payload?.params?.clubOwnerId}`,
    headers: commonHeaders,
  }),
  activateSubscription: payload => ({
    method: "POST",
    url: `/api/v1/stripe/subscription`,
    headers: commonHeaders,
    data: {},
  }),

  cancelSubscription: payload => ({
    method: "DELETE",
    url: `/api/v1/stripe/removeSubscription`,
    headers: commonHeaders,
  }),

  addPaymentMethod: payload => ({
    method: "POST",
    url: `/api/v1/stripe/create-customer`,
    headers: commonHeaders,
    data: payload,
  }),

  updatePaymentMethod: payload => ({
    method: "PUT",
    url: `/api/v1/stripe/updateCard`,
    headers: commonHeaders,
    data: payload,
  }),

  removeCard: payload => ({
    method: "DELETE",
    url: `/api/v1/stripe/removeCard`,
    headers: commonHeaders,
  }),

  updateIsTrialModalVisible: payload => ({
    method: "PUT",
    url: `/api/v1/stripe/updateIsTrialModalVisible`,
    headers: commonHeaders,
    data: payload,
  }),
}

export const notificationsApi = {
  sendNotification: body => ({
    method: "POST",
    url: "/api/v1/notifications",
    headers: commonHeaders,
    data: JSON.stringify(body),
  }),
  getNotifications: clubId => ({
    method: "GET",
    url: `/api/v1/notifications/${clubId}`,
    headers: commonHeaders,
  }),
  updateSeenNotification: clubId => ({
    method: "PUT",
    url: `/api/v1/notifications/updateSeen/${clubId}`,
    headers: commonHeaders,
  }),
}
