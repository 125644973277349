import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import apiClient from "../../api/apiClient"
import { users } from "../../api/apiRequests"

const initialState = {
  showProfileVerificationModal: false,
  isLoading: false,
  verifyEmailLoader: false,
  error: "",
}

const blockingUser = createSlice({
  name: "blockingUser",
  initialState,
  reducers: {
    setProfileVerificationModal: (state, action) => {
      state.showProfileVerificationModal = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setVerifyEmailLoader: (state, action) => {
      state.verifyEmailLoader = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
  },
})

export const {
  setProfileVerificationModal,
  setIsLoading,
  setVerifyEmailLoader,
  setError,
} = blockingUser.actions

export const resendEmailVerification = createAsyncThunk(
  "user/resendVerificationEmail",
  async (body, { dispatch }) => {
    try {
      dispatch(setIsLoading(true))
      await apiClient(users.resendVerificationEmail(body))
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      dispatch(setError(error.message))
    }
  }
)

export const verifyProfile = createAsyncThunk(
  "user/verifyProfile",
  async (body, { dispatch }) => {
    try {
      dispatch(setVerifyEmailLoader(true))
      let res = await apiClient(users.verifyEmail(body))
      if (res.status == 200) {
        dispatch(setVerifyEmailLoader(false))
        dispatch(setProfileVerificationModal(false))
      }
    } catch (error) {
      dispatch(setVerifyEmailLoader(false))
      dispatch(setError("Link is expired"))
    }
  }
)

export default blockingUser.reducer
