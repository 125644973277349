import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import apiClient from "../../api/apiClient"
import { products } from "../../api/apiRequests"

let initialStates = {
  courtData: [],
  courtInfo: {
    courtName: "",
    courtType: "",
    sports: "",
    status: "",
    bookingLength: "",
  },
  loading: false,
}

const createProduct = createAsyncThunk("club/addProducts", async thunkAPI => {
  try {
    const res = await apiClient(products.addProduct(thunkAPI))
    return res.data
  } catch (e) {
    console.log("err create product:", e)
    return e
  }
})

export const getAllProducts = createAsyncThunk(
  "club/allProducts",
  async thunkAPI => {
    try {
      const res = await apiClient(products.allProducts(thunkAPI))
      return res.data
    } catch (e) {
      console.log("err getting all products:", e)
      return e
    }
  }
)

const editProduct = createAsyncThunk(
  "club/editProduct",
  async (thunkAPI, { dispatch }) => {
    try {
      const res = await apiClient(products.editProduct(thunkAPI))

      dispatch(actions.editCourt({ data: res.data.data, id: res.data.data.id }))
    } catch (e) {
      return e
    }
  }
)

const deleteProduct = createAsyncThunk(
  "club/deleteProduct",
  async (thunkAPI, { dispatch }) => {
    try {
      const res = await apiClient(products.deleteProduct(thunkAPI))
      dispatch(actions.removeCourt({ id: thunkAPI.id }))
      return res
    } catch (e) {
      return e
    }
  }
)

export const courtSettingSlice = createSlice({
  name: "courts-settings",
  initialState: {
    ...initialStates,
  },

  reducers: {
    courtFieldsInfo: (state, action) => {
      const { key1, key2, value } = action.payload

      state[key1][key2] = value
    },

    editCourt: (state, action) => {
      const { data, id } = action.payload

      let index = state.courtData.findIndex(item => item.id === id)

      state.courtData[index] = data
    },

    selectedCourt: (state, action) => {
      state.courtInfo = action.payload
    },
    removeSelectedCourt: state => {
      state.courtInfo = initialStates.courtInfo
    },

    addCourt: (state, action) => {
      state.courtData = [...state.courtData, action.payload]
    },
    removeCourt: (state, action) => {
      let { id } = action.payload

      state.courtData = state.courtData.filter(item => item.id !== id)
    },

    resetCourtSlice: () => initialStates,
  },
  extraReducers: {
    [createProduct.pending]: state => {
      state.loading = true
    },
    [createProduct.fulfilled]: (state, action) => {
      const { payload } = action
      state.courtData = [...state.courtData, payload.data]
      state.loading = false
    },
    [createProduct.rejected]: (state, action) => {
      state.loading = false
    },

    [getAllProducts.pending]: (state, action) => {
      state.loading = true
    },

    [getAllProducts.fulfilled]: (state, action) => {
      state.loading = false
      state.courtData = action.payload?.data || []
    },

    [getAllProducts.rejected]: (state, action) => {
      state.loading = false
    },
  },
})

export const {
  addCourt,
  removeCourt,
  resetCourtSlice,
  courtFieldsInfo,
  selectedCourt,
  removeSelectedCourt,
  editCourt,
} = courtSettingSlice.actions

export const actions = {
  editProduct,
  addCourt,
  removeCourt,
  resetCourtSlice,
  courtFieldsInfo,
  selectedCourt,
  removeSelectedCourt,
  createProduct,
  getAllProducts,
  editCourt,
  deleteProduct,
}

export default courtSettingSlice.reducer
